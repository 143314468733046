import React from "react";
import NotFound from "../src/staticPages/NotFound/NotFound";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetStaticProps } from "next";
import HttpClient from "../utils/HttpClient";
import {
  BaseDataForStaticPage,
  getBaseDataForStaticPage,
} from "../utils/getBaseDataForStaticPage";
import Layout from "../src/components/layout";

const NotFoundPage = (): React.ReactElement => {
  return <NotFound />;
};

export default NotFoundPage;

NotFoundPage.getLayout = function getLayout(
  data: BaseDataForStaticPage,
  component: React.ReactElement
) {
  const { routes, topBars, slugs } = data;
  return (
    <Layout routes={routes} topBars={topBars} slugs={slugs}>
      {component}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  let props = {};

  if (locale) {
    const httpClient = new HttpClient(locale);
    const data = await getBaseDataForStaticPage(httpClient);
    props = { ...data, ...(await serverSideTranslations(locale, ["common"])) };
  }

  return {
    props,
  };
};
