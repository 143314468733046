import React from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";

const NotFound: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="notFound">
      <div className="notFound__content">
        <h4 className="notFound__header">{t("404-header")}</h4>
        <p className="notFound__info">{t("404-info")}</p>
        <p className="notFound__info notFound__info--small">{t("404-info2")}</p>
        <img
          className="notFound__img"
          src="/pagesAssets/NotFound/404icon.png"
          alt="not found icon"
        />
        <Link href="/">
          <button className="BasicButton notFound__btn">{t("404-link")}</button>
        </Link>
      </div>
      <img
        className="notFound__waves"
        src="/pagesAssets/NotFound/lines.svg"
        alt="Inverted waves"
      />
    </div>
  );
};

export default NotFound;
